import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";

class Header extends React.Component {
  state = {};
  render() {
    return (
      <>
        <NavBar/>
        <Outlet/>
      </>
    );
  }
}

export default Header;

import React  from "react";
import contactImage from './Contact.jpg'
import './Contact.css'

const ImagePath = require("./../Images/Contact.jpg");


export const Contact = () => {
  const parallax = {
    /* The image used */
    backgroundImage: 'url('+ImagePath+')'
  }

  return (
    <section>
    <div className="ImageContainer" style={parallax}/>
    <div className="container">
    <div className='container-fluid px-5 my-5'>
      <div className="navdiv" id="Contact"/>
      <div className='row justify-content-center'>
        <div className='col-xl-10'>
          <div className='card border-0 rounded-3 shadow-lg overflow-hidden'>
            <div className='card-body p-0'>
              <div className='row g-0'>
                <div className='col-sm-6 d-none d-sm-block '>
                  <img className='contactImgage' src={contactImage} alt="Contact"/>
                </div>
                <div className='col-sm-6 p-4 d-flex align-items-center'>
                  <div className='text-center align-self-center flex-fill'>
                    <div className='h3 fw-light'>Volg ons</div>
                    <a href="https://www.facebook.com/DVoteZwolle">
                        <span className="sr-only">facebook</span>
                        <i className="fa fa-facebook"/>
                    </a>
                    <a href="https://www.youtube.com/channel/UC-CGO1FgtSQAFuVlPDBGlDw">
                        <span className="sr-only">youtube</span>
                        <i className="fa fa-youtube"/>
                    </a>
                    <div className='h3 fw-light'>Of kom in contact via</div>

                    <a id="mail" href = "mailto: dvote.zwolle@gmail.com">dvote.zwolle@gmail.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    </section>
  );
};

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Front from "./Pages/Frontpage/Front";
import Header from "./Pages/Frontpage/Header";





class App extends React.Component {
  render(){
  return(
  <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header/>}>
          <Route index element={<Front/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
    </>
  );
}
}

export default App;

import React from "react";
import "./Navbar.css";
import logo from"../Images/logo.png";

class NavBar extends React.Component {
  toggleBurgerMenu() {
    document.querySelector(".navbar-menu").classList.toggle("is-active");
  }

  // Add the sticky className to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position

  render() {
    return (
      <>
      <nav className="navbar navbar-light fixed-top navbar-expand-lg bg-light" id="navbar">
      <div className="container-fluid">
      <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
            
        <span className="navbar-toggler-icon"></span>
        </button>
        <a className="navbar-brand" href="/"><img src={logo} alt="logo" style={{"width": 120, "height": 120}}/></a>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#About"
                >Over ons</a
              >
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#Galerij"
                >Galerij</a
              >
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#Portfolio"
                >Portfolio</a
              >
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#Agenda"
                >Agenda</a
              >
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#Contact"
                >Contact</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div
          data-bs-spy="scroll"
          data-bs-target="#navbar"
          data-bs-smooth-scroll="true"
          className="scrollspy p-3 rounded-2"
          tabIndex={0}
          id="page"
        ></div></>
    );
  }
}
export default NavBar;

import React, { Component } from "react";
import {marked} from "marked";
import "./Content.css";

export default class Content extends Component {
  constructor(props) {
    super(props);
    this.ImagePath = require("./../Images/" + this.props.page +".jpg");
    
    this.state = {
      markdown: "",
    };
  }

  componentDidMount() {
    const readmePath = require("./../md/" + this.props.page +".md");

    fetch(readmePath)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        this.setState({
          markdown: marked(text),
        });
      });
  }

  render() {
    const { markdown } = this.state;
    const parallax = {
      /* The image used */
      backgroundImage: 'url('+this.ImagePath+')',
    }

    const navdiv = {
      position: "relative",
      top: "-150px"
    }
    return (
      <section >
        <div className="ImageContainer" style={parallax}/>
        <div className="container">
          <div id={this.props.page} style={navdiv}/>
          <article className="article" dangerouslySetInnerHTML={{ __html: markdown }}></article>
        </div>
      </section>
    );
  }
}

import React, { Component } from "react";
import "./Gallery.css";
import { v4 as uuidv4 } from "uuid";

const importAll = (r) => {
  return r.keys().map(r);
};

const images = importAll(require.context("./images", false, /\.jpg$/));

export default class Gallery extends Component {
  constructor(props) {
    super(props);
    this.array = images;
    this.ImagePath = require("./../Images/Gallery.jpg");
  }

  render() {
    const parallax = {
        /* The image used */
        backgroundImage: 'url('+this.ImagePath+')'
      }
      const navdiv = {
        position: "relative",
        top: "-250px"
      }
    return (
        <section>
        <div className="ImageContainer" style={parallax}/>
        <div className='container' style={{paddingBottom: "20px"}}>
        <h1 style={{paddingLeft: "10px"}}>Galerij</h1>
        <div className='container'>
            <div id="Galerij" style={navdiv}/>
          <div className='row gx-2 justify-content-center'>
            {this.array.map((src, index) => (
              <div key={uuidv4()} className='col-lg-4 col-md-6'>
                <div
                  className='center-cropped'
                  style={{backgroundImage:`url(${src})`}}>
                  <img src={src} alt={src}/>
                </div>
              </div>
            ))}
          </div>
        </div>
        </div>
      </section>
    );
  }
}

import React from "react";
import { Contact } from "../Contact/Contact";
import Content from "../ContentPage/Content";
import Gallery from "../Gallery/Gallery";
class Front extends React.Component {
  render() {
    return (
      <div className="Front">
        
        <Content page="About"/>
        <Gallery/>
        <Content page="Portfolio"/>
        <Content page="Agenda"/>
        <Contact/>

      </div>
    );
  }
}

export default Front;
